<template>
  <div class="outer" ref="outer">
    <div
      class="slide"
      ref="slide"
      :style="{
        transform: `translate(${!direction ? cur : 0}px,${
          direction ? cur : 0
        }px)`,
        width: direction ? '100%' : 'auto',
        height: !direction ? '100%' : 'auto'
      }"
    >
      <!-- <div
        ref="loading"
        :class="[direction ? 'loadingH' : 'loadingW']"
        v-if="loading"
      >
        <slot name="loading"></slot>
      </div> -->
      <slot></slot>
      <!-- <div :class="[direction ? 'loadingH' : 'loadingW']" v-if="loading">
        <slot name="loading"></slot>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'isScroll',
  props: {
    // true=竖向滚动 false=横向滚动
    direction: {
      type: Boolean,
      default: true
    },
    // 碰底事件
    callback: {
      type: Function,
      default: () => {}
    },
    // 是否需要加载的------
    // loading: {
    //   type: Boolean,
    //   default: false
    // },
    // // 是否隐藏加载------
    // isLoading: {
    //   type: Boolean,
    //   default: false
    // },
    // 是否自动滚动
    autoplay: {
      type: Boolean,
      default: false
    },
    autoplayStop: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isTouch: typeof window !== 'undefined' && 'ontouchstart' in window,
      downX: 0, // 鼠标按下的位置
      downY: 0, // 鼠标按下的位置
      differenceX: 0, // 计算鼠标按下位置与列表当前位置的差值,列表位置初始值为0
      differenceY: 0, // 计算鼠标按下位置与列表当前位置的差值,列表位置初始值为0
      timeStamp: 0, // 按下的时间
      isDown: false, // 是否按了鼠标
      cur: 0, // 列表滑动位置
      offset: 50, // 最大溢出值
      velocity: 0, // 滑动的力度
      elastic: 150, // 弹力,值越大,到度或到顶后,可以继续拉的越远
      isScroll: false, // 是否在滚动中
      scrollHeight: 0, // 列表的高度
      clientHeight: 0, // 容器的高度,
      loadingWH: 0 // 加载效果的
    }
  },
  created () {},
  watch: {
    cur (val) {
      if (val <= 0 && val >= -5 && !this.isScroll) {
        clearTimeout(this.topSlide)
        this.topSlide = setTimeout(() => {
          this.callback(-1)
        }, 100)
      }
      if (
        val <= this.clientHeight - this.scrollHeight + 5 &&
        val >= this.clientHeight - this.scrollHeight &&
        !this.isScroll
      ) {
        clearTimeout(this.topSlide)
        this.topSlide = setTimeout(() => {
          this.callback(1)
        }, 100)
      }
    },
    autoplayStop () {
      this.autoplayRoll()
    }
  },
  methods: {
    onStart (e) {
      if (this.isScroll) return
      clearInterval(this.tirm)
      this.isDown = true
      this.velocity = 0
      this.scrollHeight = this.direction
        ? this.$refs.outer.scrollHeight
        : this.$refs.outer.scrollWidth
      this.clientHeight = this.direction
        ? this.$refs.outer.clientHeight
        : this.$refs.outer.clientWidth
      // 记录当前鼠标手指触摸的位置点
      this.downX = this.isTouch ? e.touches[0].clientX : e.clientX
      this.downY = this.isTouch ? e.touches[0].clientY : e.clientY
      this.timeStamp = e.timeStamp
      this.differenceX = this.downX - this.cur
      this.differenceY = this.downY - this.cur
      // 鼠标手指滑动屏幕
      this.$refs.outer.addEventListener(
        this.isTouch ? 'touchmove' : 'mousemove',
        this.move,
        false
      )
      // 鼠标手指离开屏幕事件
      this.$refs.outer.addEventListener(
        this.isTouch ? 'touchend' : 'mouseup',
        this.end,
        false
      )
      this.$refs.outer.addEventListener(
        this.isTouch ? 'touchend' : 'mouseleave',
        this.end,
        false
      )
    },
    // 手指鼠标滑动
    move (e) {
      // 会阻止同层级事件的冒泡
      e.stopImmediatePropagation()
      // 当前滑动的位置
      let moveY = this.isTouch ? e.touches[0].clientY : e.clientY
      let moveX = this.isTouch ? e.touches[0].clientX : e.clientX
      if (this.isDown) {
        if (e.timeStamp - this.timeStamp > 40) {
          this.timeStamp = e.timeStamp
          this.cur = this.direction
            ? moveY - this.differenceY
            : moveX - this.differenceX
          if (this.cur > 0) {
            this.cur *= this.elastic / (this.elastic + this.cur)
          } else if (this.cur < this.clientHeight - this.scrollHeight) {
            this.cur += this.scrollHeight - this.clientHeight
            this.cur =
              (this.cur * this.elastic) / (this.elastic - this.cur) -
              this.scrollHeight +
              this.clientHeight
          }
        }
        this.velocity = this.direction ? moveY - this.downY : moveX - this.downX
        this.downY = moveY
        this.downX = moveX
      }
    },
    // 鼠标抬起手指离开
    end () {
      if (this.isDown) {
        this.isDown = false
        let friction = ((this.velocity >> 31) * 2 + 1) * 0.5 // 根据力度套用公式计算出惯性大小,公式要记住
        let visual = this.scrollHeight - this.clientHeight
        this.tirm = setInterval(() => {
          this.velocity -= friction
          this.cur += this.velocity
          if (-this.cur - visual > this.offset) {
            clearInterval(this.tirm)
            this.ease(-visual) // 回弹
            return
          }
          if (this.cur > this.offset) {
            clearInterval(this.tirm)
            this.ease(0) // 回弹
            return
          }
          if (Math.abs(this.velocity) < 1) {
            clearInterval(this.tirm)
            if (this.cur > 0) {
              this.ease(0)
              return
            }
            if (-this.cur > visual) {
              this.ease(-visual)
            }
          }
          this.$refs.outer.removeEventListener(
            this.isTouch ? 'touchmove' : 'mousemove',
            this.move,
            false
          )
          this.$refs.outer.removeEventListener(
            this.isTouch ? 'touchend' : 'mouseup',
            this.endRemove,
            false
          )
          this.$refs.outer.removeEventListener(
            this.isTouch ? 'touchend' : 'mouseleave',
            this.endRemove,
            false
          )
        }, 20)
      }
    },
    ease (target) {
      this.isScroll = true
      this.tirm1 = setInterval(() => {
        this.cur -= (this.cur - target) * 0.2
        if (Math.abs(this.cur - target) < 1) {
          this.cur = target
          clearInterval(this.tirm1)
          this.isScroll = false
        }
      }, 20)
    },
    // 监控loading效果的宽高
    observerDom () {
      // this.loadingWH = this.direction
      //   ? this.$refs.loading.offsetHeight
      //   : this.$refs.loading.offsetWidth
      // this.cur = -this.loadingWH
    },
    autoplayRoll () {
      const outer = this.$refs.outer.getBoundingClientRect()
      const slide = this.$refs.slide.getBoundingClientRect()
      if (!this.autoplayStop) {
        clearTimeout(this.slideTirm)
        return
      }
      if (Math.abs(this.cur) >= slide.height && this.direction) {
        clearTimeout(this.slideTirm)
        this.cur = outer.height
      }
      if (Math.abs(this.cur) >= slide.width && !this.direction) {
        clearTimeout(this.slideTirm)
        this.cur = outer.width
      }
      this.slideTirm = setTimeout(() => {
        this.cur -= 1
        this.autoplayRoll()
      }, 10)
    }
  },
  mounted () {
    // 绑定触摸屏幕事件
    if (!this.autoplay) {
      this.$refs.outer.addEventListener(
        this.isTouch ? 'touchstart' : 'mousedown',
        this.onStart
      )
    }
    if (this.autoplay) {
      this.autoplayRoll()
    }

    // if (this.loading) {
    //   this.observerDom()
    //   const ObserverDom =
    //     window.MutationObserver ||
    //     window.WebKitMutationObserver ||
    //     window.MozMutationobserver
    //   const observerDom = new ObserverDom(this.observerDom)
    //   observerDom.observe(this.$refs.loading, {
    //     childList: true,
    //     attributes: true,
    //     data: true,
    //     characterData: true,
    //     subtree: true
    //   })
    // }
  },
  beforeDestroy () {
    this.$refs.outer.removeEventListener(
      this.isTouch ? 'touchstart' : 'mousedown',
      this.onStart
    )
    if (this.slideTirm) {
      clearTimeout(this.slideTirm)
    }
  }
}
</script>

<style lang="less" scoped>
.outer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
}

.loadingH {
  width: 100%;
  height: auto;
  flex-shrink: 0;
}
.loadingW {
  width: auto;
  height: 100%;
  flex-shrink: 0;
}
</style>
