<template>
  <dv-border-box-8 v-if="marketList" :dur="10" class="leftList">
    <isScroll>
      <div
        :class="['item', id === item.id ? 'item1' : '']"
        v-for="item in marketList"
        :key="item.id"
        @click="chang(item.id)"
      >
        {{ item.comp_name }}
      </div>
    </isScroll>
  </dv-border-box-8>
</template>

<script>
import isScroll from './scroll.vue'
import { getMarket } from '@api/index'
export default {
  name: 'leftList',
  components: { isScroll },
  props: {
    callback: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      marketList: '',
      id: ''
    }
  },

  created () {
    this.getMarket()
  },
  methods: {
    getMarket () {
      getMarket().then(res => {
        this.marketList = res.data
        this.id = res.data[0].id
        this.callback(res.data[0].id)
      })
    },
    chang (id) {
      this.id = id
      this.callback(id)
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
/deep/ .border-box-content {
  padding: 0.04rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.leftList {
  height: 100%;
  font-size: 0.22rem;
  color: #fff;
}
.outer1 {
  width: 100%;
  height: 100%;
}
.item {
  padding: 0.1rem;
  box-sizing: border-box;
  cursor: pointer;
}
.item1 {
  box-shadow: #10adff 0px 0px 0.2rem 0.03rem inset;
}
</style>
