<template>
  <div class="chunkBorder flex">
    <div class="marketNmae" v-if="obj.marketName">
      <div>{{ obj.marketName }}</div>
      <div>姓名:{{ obj.name }}</div>
      <div>电话:{{ obj.tel }}</div>
    </div>
    <img class="image" :src="obj.img" alt="" />
    <div>{{ obj.typeName }}</div>
    <div class="row1Border border"></div>
    <div class="row1 flex">
      <div v-for="(item, i) in obj.row1" :key="i">
        <div>{{ item.name }}</div>
        <div>数量：{{ item.num }}</div>
      </div>
    </div>
    <div class="row2Border flex" v-if="obj.row2">
      <div class="border"></div>
      <div class="border"></div>
    </div>
    <div class="flex row2" v-if="obj.row2">
      <div v-for="(item, i) in obj.row2" :key="i">
        <div>{{ item.name }}</div>
        <div>数量:{{ item.num }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'chunkBorder',
  props: ['obj'],
  data () {
    return {}
  },
  created () {},
  methods: {},
  mounted () {}
}
</script>
<style lang="less" scoped>
.chunkBorder {
  background-image: url('../../assets/marketName.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0.2rem;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  font-size: 0.2rem;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .marketNmae {
    position: absolute;
    top: 0.15rem;
    left: 0.2rem;
    color: #fff;
    div {
      text-align: left;
      font-size: 0.2rem;
    }
  }
  .row1Border {
    width: 60%;
    margin: 0.1rem auto 0;
  }
  .border {
    border-top: 1px solid #215aff;
    border-left: 1px solid #215aff;
    border-right: 1px solid #215aff;
    height: 0.2rem;
  }
  .row1 {
    width: 80%;
    margin: 0.1rem auto 0;
  }
  .row2 {
    width: 100%;
  }
  .row2Border {
    width: 90%;
    margin: 0.1rem auto 0;
    .border {
      width: 30%;
    }
  }
  .image {
    width: 0.82rem;
    height: 0.82rem;
  }
}
</style>
