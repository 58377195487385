<template>
  <div class="marketName flex">
    <div class="flex name">
      <span>{{ obj.marketName }}</span>
      <span>{{ obj.typeNmae }}</span>
      <span>{{ obj.num }}人</span>
    </div>
    <img class="image" src="../../assets/ClassificationBorder.png" alt="" />
    <isScroll class="isScroll flex">
      <carousels :arr="obj.arr" type="marketName" />
    </isScroll>
  </div>
</template>

<script>
import isScroll from '@comp/Detection/scroll.vue'
import carousels from '@comp/About/carousels.vue'
export default {
  name: 'marketName',
  props: ['obj'],
  components: { isScroll, carousels },
  data () {
    return {}
  },
  created () {},
  methods: {},
  mounted () {}
}
</script>
<style lang="less" scoped>
.marketName {
  background-image: url('../../assets/marketName.png');
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0.1rem;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.25rem;

  .name {
    width: 28%;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    span {
      width: 100%;
      text-align: left;
      padding: 0 0.2rem;
      box-sizing: border-box;
      user-select: none;
    }
  }
  .isScroll {
    width: 70%;
    height: 100%;
  }
  .image {
    height: 90%;
    widows: 1%;
    user-select: none;
  }
}
</style>
