<template>
  <dv-border-box-12 class="flex">
    <div class="chunkItem flex">
      <div class="flex">
        <div>{{ obj.marketName }}</div>
        <div>{{ obj.typeNmae }}</div>
        <div v-show="obj.name">姓名: {{ obj.name }}</div>
        <div v-show="obj.tel">电话号码:{{ obj.tel }}</div>
      </div>
      <img class="image" :src="obj.img" alt="" />
    </div>
  </dv-border-box-12>
</template>

<script>
export default {
  props: ['obj'],
  name: 'chunkItem',
  data () {
    return {}
  },
  created () {},
  methods: {},
  mounted () {}
}
</script>
<style lang="less" scoped>
.chunkItem {
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  box-sizing: border-box;
  color: #fff;
  font-size: 0.25rem;
  user-select: none;

  .flex {
    flex-direction: column;
    justify-content: space-around;
    width: 67%;
    height: 100%;
    div {
      text-align: left;
      width: 100%;
      user-select: none;
    }
  }
  .image {
    width: 33%;
    height: 60%;
  }
  .margin {
    margin: 0.1rem 0;
  }
}
</style>
