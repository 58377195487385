<template>
  <div class="Classification flex">
    <dv-border-box-12 class="border12">
      <isScroll style="height:100%;">
        <div
          :class="[
            'item',
            index === i ? 'item1' : '',
            i === 0 ? 'radius1' : '',
            i === list.length - 1 ? 'radius2' : ''
          ]"
          v-for="(item, i) in list"
          :key="i"
          @click="chang(i)"
        >
          {{ item.name }}
        </div>
      </isScroll>
      <div v-if="isMarketList" class="absolute">
        <leftList
          :callback="marketList"
          style="height:100%;height: 50%;background: #081941;"
        />
      </div>
    </dv-border-box-12>
    <section
      @click="isMarketList = false"
      :class="['section', 'flex', index * 1 !== 20 ? 'start' : '']"
    >
      <template v-if="index === 0 || index === 4">
        <div class="backImage flex" v-if="index === 0">
          <img class="iamge" :src="marketTitle.health_img" alt="" />
          <div class="flex titelMarket">
            <div>{{ marketTitle.comp_name }}</div>
            <div>
              姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：{{ marketTitle.health_name }}
            </div>
            <div>岗&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位：卫生负责人</div>
          </div>
          <div class="flex titelMarket">
            <div>&nbsp;</div>
            <div>联系电话：{{ marketTitle.health_tel }}</div>
            <div class="flex">
              公厕等级：<el-rate disabled v-model="rate"></el-rate>
            </div>
          </div>
        </div>
        <div class="backImage flex" v-if="index === 0">
          <div v-for="item in publicToilet" :key="item.img">
            <img class="img" :src="item.img" alt="" />
            <div>{{ item.name }}{{ item.num }}</div>
          </div>
        </div>
        <chunkBorder
          v-for="(item, i) in index === 4 ? chunkBorder1 : chunkBorder"
          :key="i"
          :obj="item"
          :style="{ width: '32%', height: index === 4 ? '30%' : '38%' }"
          :class="{ margin: margin.indexOf(i) != -1 }"
        />
      </template>
      <template v-else-if="index === 20">
        <marketName
          v-for="(item, i) in chunkItem"
          :obj="item"
          :key="i"
          style="width:49%;height: 23%;"
        />
      </template>
      <template v-else>
        <chunkItem
          v-for="(item, i) in chunkItem"
          :key="i"
          :obj="item"
          style="width:32%;height: 30%;"
          :class="{ margin: margin.indexOf(i) != -1 }"
        />
      </template>
    </section>
  </div>
</template>

<script>
import leftList from '@comp/Detection/leftList.vue'
import isScroll from '@comp/Detection/scroll.vue'
import chunkItem from '@comp/Classification/chunkItem.vue'
import marketName from '@comp/Classification/marketName.vue'
import chunkBorder from '@comp/Classification/chunkBorder.vue'
import { getServiceList, publicToilets, serviceFacilitys } from '@api/index'

export default {
  name: 'Classification',
  components: { isScroll, leftList, chunkItem, marketName, chunkBorder },
  data () {
    return {
      list: [], // 左侧分类
      index: this.$route.query.index * 1,
      isMarketList: false,
      chunkItem: [
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        },
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        },
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        },
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        },
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        },
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        },
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        },
        {
          marketName: '',
          typeNmae: '',
          name: '',
          tel: ''
        }
      ],
      margin: [],
      num: 1,
      marketTitle: {}, // 顶部
      // 顶部
      publicToilet: [
        {
          name: '空调设备数量',
          img: require('@assets/publicToilet1.png'),
          num: 0
        },
        {
          name: '烘干机设备数量',
          img: require('@assets/publicToilet2.png'),
          num: 0
        },
        {
          name: '擦手纸设备数量',
          img: require('@assets/publicToilet3.png'),
          num: 0
        }
      ],
      rate: 5,
      // 公厕
      chunkBorder: [
        {
          marketName: '',
          img: require('@assets/nance.png'),
          typeName: '男厕坑位数量',
          row1: [
            {
              name: '小便位',
              num: '2'
            },
            {
              name: '大便位',
              num: '2'
            }
          ],
          row2: [
            {
              name: '挂式',
              num: 2
            },
            {
              name: '小便池',
              num: 2
            },
            {
              name: '蹲式',
              num: 2
            },
            {
              name: '坐式',
              num: 2
            }
          ]
        },
        {
          marketName: '',
          img: require('@assets/nuce.png'),
          typeName: '女厕坑位数量',
          row1: [
            {
              name: '蹲式坑位',
              num: 2
            },
            {
              name: '女坐式',
              num: 3
            }
          ]
        },
        {
          marketName: '',
          img: require('@assets/canzhang.png'),
          typeName: '残障人士（老人）专间',
          row1: [
            {
              name: '专间坐式',
              num: 2
            },
            {
              name: '擦手设备',
              num: 2
            },
            {
              name: '扶手设备',
              num: 2
            }
          ]
        },
        {
          marketName: '',
          img: require('@assets/xishoutai.png'),
          typeName: '洗手台',
          row1: [
            {
              name: '成年洗手盆',
              num: 2
            },
            {
              name: '儿童洗手盆',
              num: 2
            }
          ]
        },
        {
          marketName: '',
          img: require('@assets/fangwen.png'),
          typeName: '除臭防蚊灭蝇设备数量',
          row1: [
            {
              name: '除臭设备',
              num: 2
            },
            {
              name: '防蚊灭蝇',
              num: 2
            }
          ]
        },
        {
          marketName: '',
          img: require('@assets/xinxi.png'),
          typeName: '信息发布点数量',
          row1: [
            {
              name: '电子式字数',
              num: 2
            },
            {
              name: '非电子式',
              num: 2
            }
          ]
        }
      ],
      // 停车位
      chunkBorder1: []
    }
  },
  watch: {
    chunkItem: {
      handler (val, value) {
        let num = val.length || value.length
        for (let index = 0; index < num; index++) {
          if (this.num === index) {
            this.margin.push(this.num)
            this.num += 3
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.getServiceList()
    let index = 0
    switch (this.$route.query.index * 1) {
      case 1:
        index = 1
        break
      case 2:
        index = 11
        break
      case 3:
        index = 10
        break
      case 4:
        index = 5
        break
      case 5:
        index = 6
        break
      case 6:
        index = 7
        break
      case 7:
        index = 8
        break
      case 8:
        index = 9
        break
      case 9:
        index = 12
        break
      case 10:
        index = 4
        break
      case 11:
        index = 19
        break
      case 12:
        index = 17
        break
      case 13:
        index = 20
        break
    }
    this.chang(index)
  },

  methods: {
    getServiceList () {
      getServiceList().then(res => {
        this.list = res.data
      })
    },
    chang (i) {
      this.index = i
      if (i === 0) {
        this.isMarketList = true
      } else {
        this.isMarketList = false
        this.serviceFacilitys(i + 1)
      }
    },
    marketList (id) {
      this.publicToilets(id)
    },
    // 公厕的信息
    publicToilets (id) {
      publicToilets(id).then(res => {
        let marketTitle = {
          comp_name: res.data.informations.comp_name,
          health_img: res.data.health_img,
          health_name: res.data.health_name,
          health_tel: res.data.health_tel
        }
        this.publicToilet[0].num = res.data.air_num
        this.publicToilet[1].num = res.data.dryer_num
        this.publicToilet[2].num = res.data.paper_cleaning_equipment_num

        this.chunkBorder[0].typeName = '男厕坑位数量' + res.data.man_choice_num
        this.chunkBorder[0].row1[0].num = res.data.man_choice_pee_urinal
        this.chunkBorder[0].row1[1].num = res.data.man_choice_pee
        this.chunkBorder[0].row2[0].num = res.data.man_choice_pee_squat_urinal
        this.chunkBorder[0].row2[1].num = res.data.man_choice_pee_sit_urinal
        this.chunkBorder[0].row2[2].num = res.data.man_choice_pee_squat
        this.chunkBorder[0].row2[3].num = res.data.man_choice_pee_sit
        this.chunkBorder[1].row1[0].num = res.data.woman_choice_pee_squat
        this.chunkBorder[1].row1[1].num = res.data.woman_choice_pee_sit
        this.chunkBorder[1].typeName =
          '女厕坑位数量' + res.data.woman_choice_pee
        this.chunkBorder[2].row1[0].num = res.data.dp_sit
        this.chunkBorder[2].row1[1].num = res.data.dp_wipe_handheld
        this.chunkBorder[2].row1[2].num = res.data.dp_handheld

        this.chunkBorder[3].typeName = '洗手台数量' + res.data.wash_basin_num
        this.chunkBorder[3].row1[0].num = res.data.adult_wash_basin
        this.chunkBorder[3].row1[1].num = res.data.child_wash_basin

        this.chunkBorder[4].typeName = '除臭防蚊灭蝇设备数量' + res.data.fly_num
        this.chunkBorder[4].row1[0].num = res.data.deodorize_num
        this.chunkBorder[4].row1[1].num = res.data.mosquito_fly_num

        this.chunkBorder[5].typeName =
          '信息发布点数量' + res.data.electronic_num
        this.chunkBorder[5].row1[0].num = res.data.electronic
        this.chunkBorder[5].row1[1].num = res.data.non_electronic

        this.marketTitle = marketTitle
      })
    },
    serviceFacilitys (id) {
      serviceFacilitys(id).then(res => {
        const chunkItem = []
        const chunkBorder1 = []
        res.data.list.forEach(item => {
          if (id * 1 === 5) {
            chunkBorder1.push({
              marketName: item.comp_name,
              typeName: item.type_name + '：' + item.number,
              img: item.img,
              name: item.user_manage,
              tel: item.user_tel,
              row1: [
                {
                  name: '机动车车位',
                  num: item.data.motorized_number
                },
                {
                  name: '非机动车车位',
                  num: item.data.non_motorized_number
                },
                {
                  name: '充电桩车位',
                  num: item.data.non_charging_number
                }
              ]
            })
          } else if (id * 1 === 21) {
            chunkItem.push({
              marketName: item.comp_name,
              typeNmae: item.type_name,
              num: item.number,
              arr: item.data
            })
          } else {
            chunkItem.push({
              marketName: item.comp_name,
              typeNmae: item.type_name + ':' + item.number,
              name: item.user_manage,
              tel: item.user_tel,
              img: item.img
            })
          }
        })

        if (id * 1 === 5) {
          this.chunkBorder1 = chunkBorder1
        } else {
          this.chunkItem = chunkItem
        }
      })
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.Classification {
  width: 100%;
  height: calc(100% - 0.7rem);
  padding: 0.2rem;
  box-sizing: border-box;
  overflow: hidden;
  .border12 {
    width: 15%;
    height: 100%;
    /deep/.border-box-content {
      padding: 0.05rem;
      box-sizing: border-box;
    }
  }
  .absolute {
    position: absolute;
    top: 0.05rem;
    left: 100%;
    width: 80%;
    height: 100%;
    z-index: 10;
  }
  .item {
    padding: 0.1rem;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 0.22rem;
    color: #fff;
    position: relative;
  }
  .radius1 {
    border-radius: 0.1rem 0.1rem 0 0;
  }
  .radius2 {
    border-radius: 0 0 0.1rem 0.1rem;
  }
  .item1 {
    box-shadow: #10adff 0px 0px 0.15rem 0.03rem inset;
  }
  .section {
    width: 84%;
    height: 100%;
    padding: 0.05rem;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
  .start {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .margin {
    margin: 0 2%;
  }
  .backImage {
    width: 49%;
    height: 15%;
    background-image: url('../assets/marketName.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0.2rem;
    box-sizing: border-box;
    color: #fff;
    font-size: 0.2rem;
    text-align: center;
    justify-content: space-evenly;
    .img {
      width: 0.8rem;
      height: 0.8rem;
    }
    .iamge {
      width: 0.9rem;
      height: 100%;
    }
    .titelMarket {
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
    }
  }
  .backImage:nth-child(2) {
    margin-left: 2%;
    justify-content: space-around;
  }
}
</style>
