<template>
  <div class="Detection flex">
    <leftList :callback="leftListId" style="width: 15%" />
    <section class="section flex">
      <div class="titel flex backgroundImage">食品快检公示</div>
      <div class="chunk flex">
        <div class="itemObj" v-for="item in title" :key="item.type">
          <chunk :obj="item" />
        </div>
      </div>
      <!-- echarts -->
      <div class="echarts flex">
        <div class="echartsItem">
          <chunk name="Detection">
            <bar ref="bar" :type="4" />
            <div class="absolute flex">
              <div
                :class="['timeType', timeType === 'month' ? 'blue' : '']"
                @click="timeTypeBar('month')"
              >
                月
              </div>
              <div
                :class="['timeType', timeType === 'day' ? 'blue' : '']"
                @click="timeTypeBar('day')"
              >
                日
              </div>
            </div>
          </chunk>
        </div>
        <div class="echartsItem">
          <chunk name="Detection">
            <div
              class="loading"
              v-loading="loading"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
            >
              <div class="timeTypePie backgroundImage flex">
                <span>检测类目</span>
                <div class="flex">
                  <div
                    :class="['timeType', timeTypePie === 'year' ? 'blue' : '']"
                    @click="timeTypePies('year')"
                  >
                    年
                  </div>
                  <div
                    :class="['timeType', timeTypePie === 'month' ? 'blue' : '']"
                    @click="timeTypePies('month')"
                  >
                    月
                  </div>
                  <div
                    :class="['timeType', timeTypePie === 'day' ? 'blue' : '']"
                    @click="timeTypePies('day')"
                  >
                    日
                  </div>
                </div>
              </div>
              <pie
                style="height: calc(100% - 0.5rem); margin-top: 0.1rem"
                ref="pie"
                type="chunk"
                :loadings="() => (loading = false)"
              />
            </div>
          </chunk>
        </div>
      </div>
      <div class="scroll">
        <chunk name="Detection">
          <dv-scroll-board
            v-loading="scroll"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :config="config"
            style="width:100%;height:100%"
          />
        </chunk>
      </div>
    </section>
  </div>
</template>

<script>
import leftList from '@comp/Detection/leftList.vue'
import chunk from '@comp/About/border.vue'
import bar from '@comp/home/bar.vue'
import pie from '@comp/home/pie.vue'
import { infoBox, detectionInformation } from '@api/index'
export default {
  name: 'Detection',
  components: { leftList, chunk, bar, pie },
  data () {
    return {
      title: [
        {
          name: '今日快检批次',
          count: '125',
          num: '515454',
          img:
            'https://shiankuaixian.oss-cn-shenzhen.aliyuncs.com/user/20210809/20210809055503-161.png'
        },
        {
          name: '今日合格批次',
          count: '360',
          num: '515454',
          img:
            'https://shiankuaixian.oss-cn-shenzhen.aliyuncs.com/user/20210809/20210809055503-161.png'
        },
        {
          name: '今日合格率',
          num: '5154541',
          count: '2315451',
          img:
            'https://shiankuaixian.oss-cn-shenzhen.aliyuncs.com/user/20210809/20210809055503-161.png'
        },
        {
          name: '本月累计快检批次',
          num: '5154541',
          count: '2315451',
          img:
            'https://shiankuaixian.oss-cn-shenzhen.aliyuncs.com/user/20210809/20210809055503-161.png'
        },
        {
          name: '本年累计快检批次',
          num: '5154541',
          count: '2315451',
          img:
            'https://shiankuaixian.oss-cn-shenzhen.aliyuncs.com/user/20210809/20210809055503-161.png'
        }
      ],
      marketID: '',
      timeType: 'day',
      timeTypePie: 'day',
      loading: false,
      config: {
        header: [
          '经营户',
          '检测项目',
          '检测品种',
          '检测产品',
          '检测档口',
          '检测结果',
          '检测时间'
        ],
        rowNum: 4,
        waitTime: 3000,
        headerBGC: 'rgba(0, 130, 196, 0.6)',
        oddRowBGC: 'rgba(0, 2, 92, 0.2)',
        evenRowBGC: 'rgba(0, 2, 92, 1)',
        align: [
          'center',
          'center',
          'center',
          'center',
          'center',
          'center',
          'center'
        ],
        headerHeight: '60',
        data: []
      },
      page: 1,
      lastPage: '',
      scroll: false
    }
  },
  created () {},
  methods: {
    leftListId (id) {
      this.marketID = id
      this.infoBox(id)
      this.$refs.bar.getDetectionBar({ market_id: id, type: this.timeType })
      this.loading = true
      this.$refs.pie.detectionProject({ market_id: id, type: this.timeTypePie })
      clearTimeout(this.tirm)
      let config = { ...this.config }
      config.data = []
      this.config = config
      this.detectionInformation()
    },
    infoBox (id) {
      infoBox(id).then(res => {
        let item = res.data
        this.title[0].count = '昨日：' + item.today_detection_count + '次'
        this.title[0].num = item.yesterday_detection_count + '次'
        this.title[1].count = '昨日：' + item.today_pass_count + '次'
        this.title[1].num = item.yesterday_pass_count + '次'
        this.title[2].count = '昨日：' + item.today_pass_rate + '%'
        this.title[2].num = item.yesterday_pass_rate + '%'
        this.title[3].count = '合格率：' + item.month_detection_rate + '%'
        this.title[3].num = item.month_detection_count + '次'
        this.title[4].count = '合格率：' + item.year_detection_rate + '%'
        this.title[4].num = item.year_detection_count + '次'
      })
    },
    // 柱状图的时间类型
    timeTypeBar (type) {
      if (this.timeType === type) return
      this.timeType = type
      this.$refs.bar.getDetectionBar({ market_id: this.marketID, type })
    },
    timeTypePies (type) {
      if (this.timeTypePie === type) return
      this.loading = true
      this.timeTypePie = type
      this.$refs.pie.detectionProject({ market_id: this.marketID, type })
    },
    detectionInformation () {
      clearTimeout(this.tirm)
      let params = {
        market_id: this.marketID,
        perPage: 10,
        page: this.page
      }
      if (this.page === 1) {
        this.scroll = true
      }
      detectionInformation(params).then(res => {
        const config = { ...this.config }
        res.data.data.forEach(item => {
          item.result =
            item.result * 1 === 0
              ? '不合格'
              : item.result * 1 === 1
              ? '合格'
              : '疑似'
          let arr = [
            item.seller?.name,
            item.detection_project,
            item.detection_breed,
            item.product?.veg_name,

            item.booth.booth_numbers,
            item.result,
            item.date
          ]
          config.data.push(arr)
        })
        this.config = config
        if (this.page === 1) {
          this.scroll = false
        }
        this.page += 1
        this.lastPage = res.datalast_page
        if (this.page >= this.lastPage) {
          clearTimeout(this.tirm)
        } else {
          this.tirm = setTimeout(this.detectionInformation, 30000)
        }
      })
    }
  },
  mounted () {}
}
</script>
<style lang="less" scoped>
.Detection {
  width: 100%;
  height: calc(100% - 0.7rem);
  padding: 0.2rem;
  box-sizing: border-box;
  /deep/.dv-scroll-board .header {
    font-size: 0.26rem;
    color: rgba(7, 219, 255, 1);
    border-radius: 0.05rem 0.05rem 0 0;
  }
  /deep/.row-item {
    font-size: 0.2rem;
  }
  .absolute {
    position: absolute;
    top: 2%;
    right: 3%;
    font-size: 0.18rem;
    color: #fff;
  }
  .timeType {
    padding: 0 0.1rem;
    box-sizing: border-box;
    margin-left: 0.1rem;
    border-radius: 0.04rem;
    cursor: pointer;
  }
  .blue {
    box-shadow: #10adff 0px 0px 0.04rem 3px inset;
  }
  .timeTypePie {
    width: 100%;
    height: 0.4rem;
    font-size: 0.18rem;
  }
  .section {
    width: 84%;
    height: 100%;
    padding: 2px 0;
    box-sizing: border-box;
    flex-direction: column;
    .titel {
      width: 100%;
      height: 9%;
      font-size: 0.32rem;
    }
    .chunk {
      width: 100%;
      height: 20%;
      .itemObj {
        width: 19%;
        height: 100%;
      }
    }
    .backgroundImage {
      background-image: url('../assets/DetectionTop.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
      color: #fff;
      padding-left: 0.2rem;
      box-sizing: border-box;
    }
  }
  .echarts,
  .scroll {
    width: 100%;
    height: 33%;
  }
  .echartsItem {
    height: 100%;
    width: 49.3%;
  }
  .loading {
    width: 100%;
    height: 100%;
  }
}
</style>
